import { redirect } from '@tanstack/react-router';

import { convertToSha1Hash } from '../common/helpers/convertToSh1Hash.helper';
import { getAngularDependencies } from '../common/hooks/useAngularDependencies';

const isAuthenticated = () => {
    const { SessionService } = getAngularDependencies();
    return SessionService.isAuthenticated();
};

export const authenticate = async ({ emailAddress, password }: { password: string; emailAddress: string }) => {
    const { SessionService } = getAngularDependencies();
    return SessionService.authenticate({
        username: emailAddress,
        password: await convertToSha1Hash(password),
    });
};

export const requireAuthentication = () => {
    if (!isAuthenticated()) {
        throw redirect({
            to: '/login',
            replace: true,
            state: { redirectOrigin: window.location.href },
        });
    }
};
