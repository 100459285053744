import { useNavigate } from '@tanstack/react-router';

import { SupplierGroupMessage } from '../../../../types/SupplierGroupMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { useSupplierGroupsPagination } from '../hooks/useSupplierGroupsPagination';
import { SupplierGroupsOverviewStateConfig } from '../SupplierGroupsOverviewPage';

export const SupplierGroupsTable = () => {
    const navigate = useNavigate();
    const { getHumanReadableValue } = useEnums();
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as SupplierGroupsOverviewStateConfig;
    const supplierGroupsQuery = useSupplierGroupsPagination(locationState.searchQuery, locationState.page);

    if (supplierGroupsQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (supplierGroupsQuery.isError) {
        return <CenteredPageError />;
    }

    if (supplierGroupsQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder
                data={supplierGroupsQuery.data?.content}
                onRowSelect={(row) => navigate({ to: '/supplier-group/$id', params: { id: row.id } })}
            >
                <TableBuilderColumn<SupplierGroupMessage> header={translate('suppliergroup.name')} width="40%">
                    {(row) => row.name}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('suppliergroup.type')} width="15%">
                    {(row) => getHumanReadableValue('supplierGroupType', row.type)}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('global.date.created')} numeric width="20%">
                    {(row) => formatDateTime({ date: row.dateCreated })}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('company.spotallowed')}>
                    {(row) => (row.spotAllowed ? <IconCheck width={16} height={16} /> : null)}
                </TableBuilderColumn>
                <TableBuilderColumn<SupplierGroupMessage> header={translate('suppliergroup.testcompany')}>
                    {(row) => (row.testCompany ? <IconCheck width={16} height={16} /> : null)}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={locationState.page}
                pageTotal={Math.ceil(supplierGroupsQuery.data.total / supplierGroupsQuery.data.pageable.size)}
                onPageChange={(page) => {
                    setLocationFieldValue('page', page);
                }}
            />
        </div>
    );
};
