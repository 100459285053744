import storage from 'local-storage-fallback';
import invariant from 'tiny-invariant';

import { CONTEXT_TYPE } from './ContextType';

function RoleFactory() {
    function readContextFromLocalStorageOrDefault() {
        const productContextFromLocalStorage = storage.getItem('productContext');
        if (
            productContextFromLocalStorage === CONTEXT_TYPE.LUBES ||
            productContextFromLocalStorage === CONTEXT_TYPE.FUEL
        ) {
            return productContextFromLocalStorage;
        }

        return CONTEXT_TYPE.LUBES;
    }

    function Role(roles, accessLevel, companyAccesses) {
        this.roles = roles;
        this.accessLevel = accessLevel;
        this.companyAccesses = companyAccesses;
        this.context = readContextFromLocalStorageOrDefault();
    }

    Role.prototype.updateContext = function (context) {
        invariant(this.mayUseContext(context), 'Role is not authorized to use this ProductContext');
        this.context = context;
        storage.setItem('productContext', context);
    };

    Role.prototype.isOneCompanyUser = function (type) {
        if (!this.companies) {
            return false;
        }

        if (!type) {
            return this.companies.length === 1;
        }

        return this.companies.filter((company) => company.type === type).length === 1;
    };

    Role.prototype.getCompanyAccess = function (companyId) {
        if (!this.companyAccesses || !companyId) {
            return undefined;
        }

        return this.companyAccesses.find((companyAccess) => companyAccess.companyId === companyId);
    };

    Role.prototype.getCompany = function (companyId) {
        if (!this.companies || !companyId) {
            return undefined;
        }

        return this.companies.find((company) => company.id === companyId);
    };

    Role.prototype.getCompaniesWithType = function (type) {
        if (!this.companies) {
            return [];
        }
        return this.companies.filter((company) => company.type === type);
    };

    Role.prototype.hasTermsAndConditionsAccepted = function () {
        if (this.isAdmin()) {
            return true;
        }

        if (!this.group) {
            return false;
        }

        return this.group.gtcAccepted;
    };

    Role.prototype.isSpotAllowed = function (companyId) {
        if (!this.companies || !companyId) {
            return false;
        }

        if (this.isSupplier()) return this.getCompany(companyId).supplierGroup.spotAllowed;

        return this.getCompany(companyId).spotAllowed;
    };

    Role.prototype.hasAnyAdminRights = function (type) {
        if (this.isAdmin()) {
            return true;
        }

        if (type) {
            const contextCompanyIds = this.companies
                .filter((company) => company.type === type)
                .map((company) => company.id);

            const contextCompanyAccesses = this.companyAccesses.filter((companyAccess) =>
                contextCompanyIds.includes(companyAccess.companyId)
            );

            return contextCompanyAccesses.find((companyAccess) => checkAdminRights(companyAccess.accessLevel));
        }

        if (checkAdminRights(this.accessLevel)) {
            return true;
        }

        return (
            this.companyAccesses &&
            this.companyAccesses.find((companyAccess) => checkAdminRights(companyAccess.accessLevel))
        );
    };

    Role.prototype.hasAnyCompanyWriteRights = function () {
        if (this.isAdmin()) {
            return true;
        }

        return (
            this.companyAccesses &&
            this.companyAccesses.find((companyAccess) => checkWriteRights(companyAccess.accessLevel))
        );
    };

    function checkWriteRights(level) {
        return level === 'ADMIN' || level === 'WRITE';
    }

    Role.prototype.hasWriteRights = function (companyId) {
        if (this.isAdmin()) {
            return true;
        }

        if (!companyId) {
            return checkWriteRights(this.accessLevel);
        }

        const companyAccess = this.getCompanyAccess(companyId);
        if (companyAccess) {
            return checkWriteRights(companyAccess.accessLevel);
        }

        return false;
    };

    function checkAdminRights(level) {
        return level === 'ADMIN';
    }

    Role.prototype.hasAdminRights = function (companyId) {
        if (this.isAdmin()) {
            return true;
        }

        if (!companyId) {
            return checkAdminRights(this.accessLevel);
        }

        const companyAccess = this.getCompanyAccess(companyId);
        if (companyAccess) {
            return checkAdminRights(companyAccess.accessLevel);
        }

        return false;
    };

    Role.prototype.hasRole = function (role) {
        return this.roles.indexOf(role) >= 0;
    };

    Role.prototype.isSupplier = function () {
        return this.hasRole('ROLE_SUPPLIER');
    };

    Role.prototype.isCustomer = function () {
        return this.hasRole('ROLE_CUSTOMER');
    };

    Role.prototype.isAdmin = function () {
        return this.hasRole('ROLE_ADMIN');
    };

    Role.prototype.getPossibleContexts = function () {
        if (this.isAdmin()) {
            return [CONTEXT_TYPE.FUEL, CONTEXT_TYPE.LUBES];
        }

        if (!this.companies) {
            return [];
        }

        return getContextsForCompanies(this.companies);
    };

    Role.prototype.mayUseContext = function (context) {
        return this.getPossibleContexts().includes(context);
    };

    Role.prototype.addCompanies = function (companies) {
        this.companies = companies;
        const preferredContextOrDefault = readContextFromLocalStorageOrDefault();

        if (this.isAdmin()) {
            this.context = preferredContextOrDefault;

            return;
        }

        const companyTypes = getContextsForCompanies(companies);
        if (companyTypes.some((type) => type === preferredContextOrDefault)) {
            this.context = preferredContextOrDefault;
        } else {
            this.context = companyTypes[0];
        }
    };

    function getContextsForCompanies(companies) {
        return [...new Set(companies.map((company) => company.type))].sort();
    }

    Role.prototype.usesCloselinkLite = function () {
        if (this.isCustomer()) {
            return this.group.closelinkLite;
        }

        return false;
    };

    return Role;
}

export default {
    name: 'Role',
    fn: RoleFactory,
};
