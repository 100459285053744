import invariant from 'tiny-invariant';

import { initialize } from '../../common/launchDarkly/launchDarklyClient';
import { AblyService } from '../../common/services/AblyService';
import { router } from '../../router';

SessionService.$inject = [
    '$rootScope',
    '$http',
    'SessionStorageService',
    'UserService',
    'jwtHelper',
    'AppSettings',
    'CustomerService',
    'SupplierService',
    'RoleService',
    'CustomerGroupService',
    'SupplierGroupService',
];

function SessionService(
    $rootScope,
    $http,
    SessionStorageService,
    UserService,
    jwtHelper,
    AppSettings,
    CustomerService,
    SupplierService,
    RoleService,
    CustomerGroupService,
    SupplierGroupService
) {
    const LOGIN_ROUTE = AppSettings.routes.api + '/login';
    const RENEW_ROUTE = AppSettings.routes.api + '/login/renew';

    return {
        loadRoleInformation: loadRoleInformation,
        isAuthenticated: isAuthenticated,
        authenticate: authenticate,
        logout: logout,
        renewSession: renewSession,
    };

    async function loadRoleInformation() {
        invariant(isAuthenticated(), 'called loadRoleInformation with an unauthenticated user');

        RoleService.create(getToken());
        const user = await UserService.get().$promise;
        const role = RoleService.addUser(user);

        if (!role.isAdmin()) {
            await enrichRoleWithGroup(role, user);
            await enrichRoleWithCompanies(role);
        }

        try {
            await initialize(role);
            AblyService.connect();
        } catch {
            // Fail silently in case LaunchDarkly has issues.
        }

        propagateAuthenticated();
        return role;
    }

    async function authenticate(credentials) {
        const headers = credentials
            ? {
                  authorization: 'Basic ' + btoa(credentials.username + ':' + credentials.password),
              }
            : {};
        await saveTokenAndLoadUserData($http.get(LOGIN_ROUTE, { headers }));
    }

    async function renewSession() {
        RoleService.reset();

        return saveTokenAndLoadUserData($http.get(RENEW_ROUTE));
    }

    async function enrichRoleWithGroup(role, user) {
        const group = await getGroupService(role).get(user.groupId).$promise;
        RoleService.addGroup(group);
    }

    async function enrichRoleWithCompanies(role) {
        const companies = await getCompanyService(role).list().$promise;
        RoleService.addCompanies(companies);
    }

    async function saveTokenAndLoadUserData(tokenPromise) {
        try {
            const response = await tokenPromise;
            const token = response.data.accessToken;
            SessionStorageService.saveToken(token);

            return loadRoleInformation();
        } catch (error) {
            return handleAuthenticateError(error);
        }
    }

    function propagateAuthenticated() {
        RoleService.authenticated();
        $rootScope.authenticated = true;
        $rootScope.$broadcast('loginSuccess');
    }

    function handleAuthenticateError(error) {
        $rootScope.authenticated = false;
        SessionStorageService.resetToken();
        return Promise.reject(error);
    }

    function getToken() {
        if (SessionStorageService.getToken()) {
            return jwtHelper.decodeToken(SessionStorageService.getToken());
        }
        return [];
    }

    function isAuthenticated() {
        if (
            !SessionStorageService.getToken() ||
            (SessionStorageService.getToken() && jwtHelper.isTokenExpired(SessionStorageService.getToken()))
        ) {
            logout();
        }

        return SessionStorageService.getToken() !== null;
    }

    function logout() {
        router.navigate({ to: '/logout' });
    }

    function getCompanyService(role) {
        if (role.isCustomer()) {
            return CustomerService;
        }
        return SupplierService;
    }

    function getGroupService(role) {
        if (role.isCustomer()) {
            return CustomerGroupService;
        }
        return SupplierGroupService;
    }
}

export default {
    name: 'SessionService',
    fn: SessionService,
};
