import { ProductContext } from '../../../types/ProductContext';
import { Text } from '../../cdl/Text/Text';
import { ButtonGroup } from '../../common/buttons/ButtonGroup';
import { OverviewCustomerCompanySelect } from '../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';

import { DownloadPriceListButton } from './components/DownloadPriceListButton';
import { InviteSupplierButton } from './components/InviteSupplierButton';
import { SuppliersOverviewTable } from './components/SuppliersOverviewTable';

export const SuppliersOverviewPage = () => {
    const stateConfig: SuppliersOverviewStateConfig = {
        page: 0,
        customerIds: [],
    };

    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <SuppliersOverviewPageContent />
        </LocationStateProvider>
    );
};

export interface SuppliersOverviewStateConfig {
    page: number;
    customerIds: string[];
}

const SuppliersOverviewPageContent = () => {
    useDocumentTitle(translate('page.suppliers'));
    const role = useRole();
    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
                    <Text variant="headline" as="h1">
                        {translate('assignments.suppliers.subheadline')}
                    </Text>

                    <ButtonGroup>
                        <DownloadPriceListButton />
                        <InviteSupplierButton />
                    </ButtonGroup>
                </Box>

                {!role.isOneCompanyUser(ProductContext.LUBES) ? (
                    <Box display="flex">
                        <OverviewCustomerCompanySelect
                            value={locationState.customerIds}
                            onChange={(customerIds) => {
                                setLocationFieldValue('customerIds', customerIds);
                            }}
                            context={ProductContext.LUBES}
                        />
                    </Box>
                ) : null}

                <SuppliersOverviewTable />
            </PageGrid>
        </Page>
    );
};
