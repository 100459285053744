import { Link as _Link, LinkProps as _LinkProps } from '@tanstack/react-router';
import { AnchorHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { IconExternalLink } from '../../common/icons/cdl/ExternalLink';
import { get } from '../../common/ui/get';

export const linkStyles = css<StyledLinkProps | TanstackLinkProps>`
    font-size: ${(props) => props.fontSize};
    color: ${get('colors.accent.foreground')};
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;

    &:hover,
    &:focus,
    &:visited,
    &:active {
        color: ${get('colors.accent.foreground')};
        text-decoration: none;
    }
`;

interface StyledLinkProps {
    fontSize: string;
}

const StyledLink = styled.a<StyledLinkProps>`
    display: inline-flex;
    align-items: center;
    gap: 4px;

    ${linkStyles}
`;

interface TanstackLinkProps extends Omit<_LinkProps, 'to'> {
    to: Exclude<_LinkProps['to'], undefined>;
    fontSize: string;
}

const StyledTanstackLink = styled(_Link)<TanstackLinkProps>`
    display: inline-block;

    ${linkStyles}
`;

type LinkProps = (
    | (AnchorHTMLAttributes<HTMLAnchorElement> & { to?: never; external?: boolean })
    | (_LinkProps & { to: TanstackLinkProps['to']; external?: never })
) & {
    size?: 'small' | 'default';
};

export const Link = ({ external, size = 'default', ...props }: LinkProps) => {
    const iconSize = size === 'small' ? 16 : 20;

    if (!external && props.to) {
        return (
            <StyledTanstackLink {...props} to={props.to} fontSize={size === 'small' ? '14px' : '16px'}>
                {props.children}
            </StyledTanstackLink>
        );
    }
    return (
        <StyledLink target={external ? '_blank' : undefined} {...props} fontSize={size === 'small' ? '14px' : '16px'}>
            {props.children}
            {external ? <IconExternalLink width={iconSize} height={iconSize} style={{ flexShrink: 0 }} /> : null}
        </StyledLink>
    );
};
