import { useNavigate } from '@tanstack/react-router';

import { CustomerGroupMessage } from '../../../../types/CustomerGroupMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { formatDateTime } from '../../../common/helpers/formatDateTime.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { IconCheck } from '../../../common/icons/cdl/Check';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { CustomerGroupsOverviewStateConfig } from '../CustomerGroupsOverviewPage';
import { useCustomerGroupsPagination } from '../hooks/useCustomerGroupsPagination';

export const CustomerGroupsTable = () => {
    const navigate = useNavigate();
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as CustomerGroupsOverviewStateConfig;
    const customerGroupsQuery = useCustomerGroupsPagination(locationState.searchQuery, locationState.page);

    if (customerGroupsQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (customerGroupsQuery.isError) {
        return <CenteredPageError />;
    }

    if (customerGroupsQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder
                data={customerGroupsQuery.data.content}
                onRowSelect={(row: CustomerGroupMessage) =>
                    navigate({ to: '/customer-group/$id', params: { id: row.id } })
                }
            >
                <TableBuilderColumn<CustomerGroupMessage> header={translate('customergroup.name')} width="50%">
                    {(row) => row.name}
                </TableBuilderColumn>
                <TableBuilderColumn<CustomerGroupMessage> header={translate('global.date.created')} numeric>
                    {(row) => formatDateTime({ date: row.dateCreated })}
                </TableBuilderColumn>
                <TableBuilderColumn<CustomerGroupMessage> header={translate('customergroup.testcompany')}>
                    {(row) => (row.testCompany ? <IconCheck width={16} height={16} /> : null)}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={locationState.page}
                pageTotal={Math.ceil(customerGroupsQuery.data.total / customerGroupsQuery.data.pageable.size)}
                onPageChange={(page) => {
                    setLocationFieldValue('page', page);
                }}
            />
        </div>
    );
};
