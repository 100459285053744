import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

export const VesselOverviewTableControls = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();
    const role = useRole();
    const { context } = useProductContext();

    return (
        <Box display="flex" gap={4}>
            <Box minWidth="300px" maxWidth="80%">
                <DebouncedSearchTextInput
                    onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                    value={locationState.searchQuery}
                    placeholder={translate('vessel.searchInputPlaceholder')}
                />
            </Box>

            {!role.isOneCompanyUser(context) ? (
                <OverviewCustomerCompanySelect
                    onChange={(customerIds) => {
                        setLocationFieldValue('customerIds', customerIds);
                        setLocationFieldValue('page', 0);
                    }}
                    value={locationState.customerIds}
                    context={!role.isAdmin() ? context : undefined}
                />
            ) : null}
        </Box>
    );
};
