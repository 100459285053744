CloselinkConfig.$inject = ['$httpProvider', '$translateProvider', 'AppSettings'];

function CloselinkConfig($httpProvider, $translateProvider) {
    $httpProvider.interceptors.push('TokenInterceptor');
    $httpProvider.interceptors.push('AuthErrorInterceptor');
    $httpProvider.interceptors.push('ExceptionInterceptor');

    $translateProvider.useStaticFilesLoader({
        prefix: '',
        suffix: '/translation.json',
    });

    $translateProvider.useLoaderCache('$templateCache');

    $translateProvider
        .registerAvailableLanguageKeys(['en'], {
            'en*': 'en',
        })
        .preferredLanguage('en')
        .fallbackLanguage('en');

    $translateProvider.useSanitizeValueStrategy('escapeParameters');
}

export default CloselinkConfig;
