import * as Sentry from '@sentry/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ParsedLocation, RouterProvider } from '@tanstack/react-router';
import { zip } from 'lodash';
// @ts-ignore
import { createRoot } from 'react-dom/client';

import { setNgDeps } from './common/hooks/useAngularDependencies';
import { ItemModel } from './common/models/ItemModel';
import AppSettings from './constants';
import { queryClient } from './lib/queryClient';
import { OrderModel } from './order/model/OrderModel';
import { registerScrollPreventionEventListener } from './registerScrollPreventionEventListener';
import { router } from './router';

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }
    interface HistoryState {
        order?: OrderModel;
        portId?: string;
        items?: ItemModel[];
        vesselId?: string;
        customerId?: string;
        bunkerStopIds?: string[];
        emailAddress?: string;
        originLocation?: ParsedLocation;
        showWelcomePopup?: boolean;
        redirectOrigin?: string;
    }
}

const NG_DEPS = [
    '$rootScope',
    '$translate',
    'SessionService',
    'RoleService',
    'PopupService',
    'SessionStorageService',
] as const;

async function Run(...deps: unknown[]) {
    const ng: Record<(typeof NG_DEPS)[number], any> = Object.fromEntries(zip(Run.$inject, deps));
    setNgDeps(ng);

    Sentry.init({
        dsn: AppSettings.SENTRY_DSN,
        environment: AppSettings.ENV,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: true,
                blockAllMedia: true,
            }),
            Sentry.tanstackRouterBrowserTracingIntegration(router),
        ],
        tracesSampleRate: 0.2,
        replaysOnErrorSampleRate: 1.0,
    });

    registerScrollPreventionEventListener();

    // NOTE: some loaders (eg. for prefetch) within RouterProvider require queryClient to be present. Hence
    // QueryClientProvider is used here.
    createRoot(document.getElementById('root')!).render(
        <QueryClientProvider client={queryClient}>
            <RouterProvider router={router} />
        </QueryClientProvider>
    );
}

Run.$inject = NG_DEPS;

export default Run;
