import { useNavigate } from '@tanstack/react-router';
import { useState } from 'react';

import { ItemMessage } from '../../../../types/ItemMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { DataTable } from '../../../common/DataTable/DataTable';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { translateSulphurContent } from '../../../common/helpers/translateSulphurContent';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { IconList } from '../../../common/icons/cdl/List';
import { ListingWithMore } from '../../../common/ListingWithMore/ListingWithMore';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { Section } from '../../../common/Section/Section';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { CircleValidity } from '../../../common/Validity/CircleValidity';
import { useOfferPagination } from '../../../offer/useOfferPagination';
import { Port } from '../../../port/Port';

export const FuelCustomerOfferTable = () => {
    const [page, setPage] = useState(0);

    const role = useRole();
    const { context } = useProductContext();
    const navigate = useNavigate();

    const offerPaginationQuery = useOfferPagination({
        page,
        types: [context],
        states: ['QUOTED'],
        sortField: 'quoteValidUntil',
        sortDirection: 'ASC',
    });

    if (offerPaginationQuery.isPending) {
        return (
            <Section title={translate('offer.openOffers')}>
                <LoadingIndicator />
            </Section>
        );
    }

    if (offerPaginationQuery.isError) {
        return (
            <Section title={translate('offer.openOffers')}>
                <Error />
            </Section>
        );
    }

    if (!offerPaginationQuery.data?.content.length) {
        return (
            <Section title={translate('offer.openOffers')}>
                <TableEmptyState Icon={IconList} text={translate('order.emptylist')} />
            </Section>
        );
    }

    const columns = [
        {
            label: translate('order.company'),
            width: '15%',
            condition: !role.isOneCompanyUser(context),
            value: (offer: any) => formatCompanyName({ company: offer.customer }) || '-',
        },
        {
            label: translate('order.vessel'),
            width: '10%',
            value: (offer: any) => formatVessel({ vessel: offer.vessel, short: true }),
        },
        {
            label: translate('order.port'),
            width: '20%',
            value: function renderPort(offer: any) {
                if (!offer.port) {
                    return '-';
                }

                return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
            },
        },
        {
            label: translate('order.supplier'),
            width: '10%',
            value: (offer: any) => formatCompanyName({ company: offer.supplier }) || '-',
        },
        {
            label: translate('order.fuelProducts'),
            width: '15%',
            value: (offer: any) => {
                return (
                    <ListingWithMore showCount={1}>
                        {offer.items.map((item: ItemMessage) => {
                            const translatedSulphur = translateSulphurContent(item.product?.sulphurContent);

                            return `${item.product?.name} ${translatedSulphur}`;
                        })}
                    </ListingWithMore>
                );
            },
        },
        {
            label: translate('order.fuelPrices'),
            width: '10%',
            value: (offer: any) => {
                if (!offer.items.some((item: ItemMessage) => item.price)) {
                    return '-';
                }

                return (
                    <ListingWithMore showCount={1}>
                        {offer.items
                            .map((item: ItemMessage) =>
                                item.price
                                    ? formatMoney({
                                          value: item.price.value,
                                          currency: item.price.currency,
                                          maximumFractionDigits: 3,
                                          minimumFractionDigits: 2,
                                      })
                                    : null
                            )
                            .filter((item: string | null) => item)}
                    </ListingWithMore>
                );
            },
        },
        {
            label: translate('order.quoteValidity'),
            width: '20%',
            value: (offer: any) => {
                if (!offer.quoteValidUntil) {
                    return '-';
                }
                return (
                    <CircleValidity
                        validityTime={offer.quoteValidityTime}
                        validUntil={offer.quoteValidUntil}
                        background="transparent"
                    />
                );
            },
        },
    ].filter(({ condition = true }) => condition);

    const onRowClick = ({ row: offer }: { row: any }) => {
        navigate({ to: '/fuel/order/$id', params: { id: offer.orderId } });
    };

    return (
        <Section title={translate('offer.openOffers')}>
            <DataTable
                columns={columns}
                rows={offerPaginationQuery.data.content}
                onRowClick={onRowClick}
                isRowUnread={({ row: offer }) => !offer.read}
            />

            <CenteredPagination
                pageTotal={offerPaginationQuery.pageTotal}
                currentPage={page}
                onPageChange={(page) => setPage(page)}
            />
        </Section>
    );
};
