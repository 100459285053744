import { Tag, TagProps } from '../../../cdl/Tag/Tag';
import { translate } from '../../../common/helpers/translate.helper';
import { OfferState } from '../../../offer/model/OfferState';

const getStateVariant = (state: OfferState): TagProps['variant'] => {
    switch (state) {
        case OfferState.ENQUIRY:
        case OfferState.ORDER:
        case OfferState.ENQUIRY_CANCELED:
        case OfferState.QUOTE_CANCELED:
        case OfferState.CANCELED:
            return 'default';
        case OfferState.QUOTED:
        case OfferState.ACKNOWLEDGED:
            return 'accent';
        case OfferState.CONFIRMED:
            return 'positive';
        case OfferState.CUSTOMER_ADJUSTED:
        case OfferState.SUPPLIER_ADJUSTED:
            return 'attention';
        default:
            return 'default';
    }
};

interface OrderStatusTagProps {
    state: OfferState;
}

export const OrderStatusTag = ({ state }: OrderStatusTagProps) => {
    return (
        <Tag variant={getStateVariant(state)} emphasis={state === OfferState.QUOTED}>
            {translate(`state.LUBES.${state}.title`)}
        </Tag>
    );
};
