import { getAngularDependencies } from '../../common/hooks/useAngularDependencies';

AuthErrorInterceptor.$inject = ['$q', '$rootScope', '$injector'];

function AuthErrorInterceptor($q) {
    const LOGIN_ROUTE = 'api/user/login';
    const AUTH_ERRORS = [401, 403];
    return {
        responseError: function (rejection) {
            if (AUTH_ERRORS.indexOf(rejection.status) > -1 && rejection.config.url !== LOGIN_ROUTE) {
                const { SessionService } = getAngularDependencies();
                SessionService.logout();
            }
            return $q.reject(rejection);
        },
    };
}

export default {
    name: 'AuthErrorInterceptor',
    fn: AuthErrorInterceptor,
};
