import { useNavigate } from '@tanstack/react-router';

import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../../common/Error/Error';
import { formatCompanyName } from '../../../common/helpers/formatCompanyName.helper';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { formatLubesPrice } from '../../../common/helpers/formatLubesPrice.helper';
import { formatMoney } from '../../../common/helpers/formatMoney.helper';
import { formatNumber } from '../../../common/helpers/formatNumber.helper';
import { formatVessel } from '../../../common/helpers/formatVessel.helper';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconListDetails } from '../../../common/icons/cdl/ListDetails';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../../common/TabSwitch/TabSwitch';
import { Box } from '../../../common/ui/Box';
import { UnreadIndicator } from '../../../order/common/UnreadIndicator';
import { OrderStatusTag } from '../../../order/OrderOverview/lubes/OrderStatusTag';
import { Port } from '../../../port/Port';
import { OfferModel } from '../../model/OfferModel';
import { useOfferPagination } from '../../useOfferPagination';
import { OfferActions } from '../actions/OfferActions';

import { lubesTabToOfferStateMap } from './lubesTabToOfferStateMap';
import { useLubesOfferPaginationTotals } from './useLubesOfferPaginationTotals';

export const LubesOfferOverviewTable = () => {
    const { context } = useProductContext();
    const role = useRole();

    const handleSupplierIds = (supplierIds: string[]): string[] | undefined => {
        if (supplierIds.length) {
            return supplierIds;
        }

        if (role.isAdmin()) {
            return undefined;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const [locationState, { setLocationFieldValue }] = useLocationState();

    const navigate = useNavigate();

    const offerPaginationTotals = useLubesOfferPaginationTotals({
        searchQuery: locationState.searchQuery,
        supplierIds: handleSupplierIds(locationState.supplierIds),
        customerIds: locationState.customerIds,
    });
    const offersTotals = offerPaginationTotals.data ?? {};

    const offerPaginationQuery = useOfferPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        supplierIds: handleSupplierIds(locationState.supplierIds),
        customerIds: locationState.customerIds,
        types: [ProductContext.LUBES],
        // @ts-expect-error the useLocationState hook needs to be able to take a generic, so the .tab property can be typed correctly
        states: lubesTabToOfferStateMap[locationState.tab],
        sortField: locationState.sortValue?.sortField,
        sortDirection: locationState.sortValue?.sortDirection,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [
        locationState.searchQuery,
        locationState.supplierIds,
        locationState.customerIds,
        locationState.tab,
        locationState.sortValue,
    ]);

    const onTabSelect = (tab: unknown) => {
        setLocationFieldValue('tab', tab);
    };

    if (offerPaginationQuery.isPending) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
                <LoadingIndicator />
            </div>
        );
    }

    if (offerPaginationQuery.isError) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />

                <Error />
            </div>
        );
    }

    if (!offerPaginationQuery.data?.content.length) {
        return (
            <div>
                <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />
                <TableEmptyState Icon={IconListDetails} text={t('order.emptylist')} />
            </div>
        );
    }

    return (
        <div>
            <TabSwitch selectedContext={locationState.tab} onSelect={onTabSelect} totals={offersTotals} />

            <TableBuilder
                data={offerPaginationQuery.data.content}
                onRowSelect={(offer) => {
                    navigate({ to: '/offer/$id', params: { id: offer.id } });
                }}
            >
                {role.isSupplier() ? (
                    <TableBuilderColumn<OfferModel> header="" width="1%">
                        {(offer) => <Box width="8px">{!offer.supplierRead ? <UnreadIndicator /> : null}</Box>}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<OfferModel> header={t('order.vendorreferenceshort')} width="10%">
                    {(offer) => offer.vendorReference ?? '-'}
                </TableBuilderColumn>

                {role.isAdmin() || !role.isOneCompanyUser('LUBES') ? (
                    <TableBuilderColumn<OfferModel>
                        header={role.isSupplier() ? t('offer.company') : t('offer.supplier')}
                        width="10%"
                    >
                        {(offer) => formatCompanyName({ company: offer.supplier })}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<OfferModel> header={t('offer.customer')} width="10%">
                    {(offer) => formatCompanyName({ company: offer.customer })}
                </TableBuilderColumn>

                <TableBuilderColumn<OfferModel> header={t('order.vessel')} width="15%">
                    {(offer) => formatVessel({ vessel: offer.vessel, short: true })}
                </TableBuilderColumn>
                <TableBuilderColumn<OfferModel> header={t('order.port')} width="10%">
                    {(offer) => {
                        if (!offer.port) {
                            return null;
                        }

                        return <Port port={offer.port} vesselId={offer.vesselId} locode={false} showTooltip />;
                    }}
                </TableBuilderColumn>
                <TableBuilderColumn<OfferModel> header={t('order.dateDeliveryShort')} width="10%">
                    {(offer) => {
                        if (!offer.dateDelivery) {
                            return null;
                        }

                        return formatDate({
                            date: offer.dateDelivery,
                            timeZone: 'UTC',
                        });
                    }}
                </TableBuilderColumn>
                {locationState.tab === 'enquired' ? (
                    <TableBuilderColumn<OfferModel> header={t('order.volume')} width="5%">
                        {(offer) => `${formatNumber({ number: offer.volume })}L`}
                    </TableBuilderColumn>
                ) : null}
                {role.isAdmin() ? (
                    <TableBuilderColumn<OfferModel> header={t('order.ppl')} width="10%">
                        {(offer) => {
                            if (offer.ppl?.value && offer.ppl.value > 0) {
                                return formatLubesPrice(offer.ppl);
                            }

                            return null;
                        }}
                    </TableBuilderColumn>
                ) : null}
                {role.isAdmin() ? (
                    <TableBuilderColumn<OfferModel> header={t('order.total')} width="10%">
                        {(offer) => {
                            if (offer.total.value > 0) {
                                return formatMoney({
                                    value: offer.total.value,
                                    currency: offer.total.currency,
                                    minimumFractionDigits: 2,
                                });
                            }

                            return null;
                        }}
                    </TableBuilderColumn>
                ) : null}
                <TableBuilderColumn<OfferModel> header={t('order.status')} width="10%">
                    {(offer) => <OrderStatusTag state={offer.state} />}
                </TableBuilderColumn>
                {role.hasWriteRights() && role.isSupplier() ? (
                    <TableBuilderColumn<OfferModel> header={t('order.action')} width="5%">
                        {(offer) => <OfferActions offer={offer} />}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>

            <CenteredPagination
                pageTotal={offerPaginationQuery.pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
