import { CustomerGroupSelect } from '../../../common/form-elements/CustomerGroupSelect/CustomerGroupSelect';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

export const ContractOverviewTableControls = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();
    const role = useRole();

    return (
        <Box display="flex" gap={4}>
            <Box minWidth="250px">
                <DebouncedSearchTextInput
                    onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                    value={locationState.searchQuery}
                    placeholder={translate('contracts.searchInputPlaceholder')}
                />
            </Box>

            {role.isAdmin() ? (
                <Box minWidth="250px" maxWidth="80%">
                    <CustomerGroupSelect
                        onChange={(groupIds) => {
                            setLocationFieldValue('customerGroupIds', groupIds);
                        }}
                        placeholder={translate('customerGroupSelect.filterByCustomerGroups')}
                        value={locationState.customerGroupIds}
                        isMulti
                        dropdownWidth="520px"
                    />
                </Box>
            ) : null}
        </Box>
    );
};
