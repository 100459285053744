import { Text } from '../../cdl/Text/Text.tsx';
import { translate } from '../../common/helpers/translate.helper';
import { useAngularRouterLocation } from '../../common/hooks/useAngularRouterLocation';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { useVesselDetails } from '../../common/hooks/useVesselDetails';
import { useFlags } from '../../common/launchDarkly/useFlags';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { Page } from '../../common/Page/Page';
import { Section } from '../../common/Section/Section.tsx';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex.tsx';

import { AverageConsumptionSection } from './AvarageConsumptionSection/AverageConsumptionSection';
import { FuelInfoSection } from './FuelInfoSection';
import { LubesInfoSection } from './LubesInfoSection';
import { LubesOrderSection } from './LubesOrderSection';
import { FuelScheduleSection } from './ScheduleSection/FuelScheduleSection';
import { LubesScheduleSection } from './ScheduleSection/LubesScheduleSection';
import { VesselRequisitionToolbar } from './VesselRequisitionToolbar';
import { HeaderWithLine } from '../../common/HeaderWithLine/HeaderWithLine';

export const VesselDetailPage = () => {
    const role = useRole();
    const { id } = useAngularRouterLocation();
    const { isPending, isError, data: vessel } = useVesselDetails(id);
    const { isLubes, isFuel } = useProductContext();

    useDocumentTitle(
        vessel
            ? translate('vessel.documentTitle', {
                  name: vessel.name,
              })
            : null,
        [vessel]
    );

    if (isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (isError) {
        return (
            <Page>
                <Section title={translate('vessel.info')}>
                    <Text as="p">{translate('vessel.detailsLoadingError')}</Text>
                </Section>
            </Page>
        );
    }

    return (
        <Page>
            <Box display="flex" flexDirection="column" gap={9}>
                <Box display="flex" justifyContent="space-between" gap={6}>
                    <Text as="h1" variant="headline">
                        {vessel.name}
                        &nbsp;
                        <Text color="dark-blue.3">{vessel.imo}</Text>
                    </Text>

                    {isLubes && role.hasWriteRights(vessel.customerId) ? (
                        <VesselRequisitionToolbar vessel={vessel} />
                    ) : null}
                </Box>

                {isLubes ? (
                    <>
                        <LubesInfoSection vessel={vessel} />
                        <AverageConsumptionSection vesselId={vessel.id} customerId={vessel.customerId} />
                        <LubesScheduleSection vessel={vessel} />
                        <LubesOrderSection vessel={vessel} />
                    </>
                ) : null}

                {isFuel ? (
                    <>
                        <FuelInfoSection vessel={vessel} />
                        <FuelScheduleSection vessel={vessel} />
                    </>
                ) : null}
            </Box>
        </Page>
    );
};
