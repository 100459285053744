import PropTypes from 'prop-types';

import { useOfferMarkRead } from './useOfferMarkRead';
import { OverviewReadButton } from '../../../common/buttons/OverviewReadButton/OverviewReadButton';

export const OfferActions = ({ offer }) => {
    const { mutate: markRead } = useOfferMarkRead();

    const onMarkRead = (offer) => {
        markRead({ offer, offerId: offer.id, read: !offer.supplierRead });
    };

    return (
        <div>
            <OverviewReadButton onClick={() => onMarkRead(offer)} isRead={offer.supplierRead} />
        </div>
    );
};

OfferActions.propTypes = {
    offer: PropTypes.object.isRequired,
};
