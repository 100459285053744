import { ProductContext } from '../../../../types/ProductContext';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';

export const CustomerFilterActions = () => {
    const role = useRole();
    const [locationState, { setLocationFieldValue }] = useLocationState();

    if (role.isOneCompanyUser(ProductContext.LUBES)) {
        return null;
    }

    return (
        <OverviewSupplierCompanySelect
            value={locationState.supplierIds}
            onChange={(supplierIds) => {
                setLocationFieldValue('supplierIds', supplierIds);
            }}
            context={ProductContext.LUBES}
        />
    );
};
