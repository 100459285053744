import { Button } from '../cdl/Button/Button';
import { useDrawer } from '../cdl/Drawer/useDrawer.hook';
import { Text } from '../cdl/Text/Text';
import { CustomerGroupSelect } from '../common/form-elements/CustomerGroupSelect/CustomerGroupSelect';
import { translate } from '../common/helpers/translate.helper';
import { useDocumentTitle } from '../common/hooks/useDocumentTitle';
import { useRole } from '../common/hooks/useRole';
import { useLocationState } from '../common/LocationState/useLocationState';
import { Page } from '../common/Page/Page';
import { DebouncedSearchTextInput } from '../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../common/ui/Box';

import { CreateKeyPortForm } from './CreateKeyPortForm';
import { KeyPortOverviewState } from './KeyPortOverviewState';
import { KeyPortOverviewTable } from './KeyPortOverviewTable';
import { PageGrid } from '../common/PageGrid';

export const KeyPortOverviewPage = () => {
    return (
        <KeyPortOverviewState>
            <KeyPortOverviewPageContent />
        </KeyPortOverviewState>
    );
};

const KeyPortOverviewPageContent = () => {
    useDocumentTitle(translate('keyPorts.overviewPageTitle'));
    const [locationState, { setLocationFieldValue }] = useLocationState();

    const { push, pop } = useDrawer();
    const role = useRole();

    const openCreateModal = () => {
        push({
            content: <CreateKeyPortForm onSuccess={pop} />,
        });
    };

    const handleGroupSelection = (customerGroupIds) => {
        setLocationFieldValue('customerGroupIds', customerGroupIds);
    };

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
                    <Text variant="headline" as="h1">
                        {translate('keyPorts.overviewPageTitle')}
                    </Text>

                    {role.hasWriteRights() ? (
                        <Button emphasis="high" onClick={openCreateModal}>
                            {translate('keyPorts.add')}
                        </Button>
                    ) : null}
                </Box>

                <Box display="flex" alignItems="center" gap={4}>
                    <Box minWidth="250px">
                        <DebouncedSearchTextInput
                            placeholder={translate('keyPorts.searchInputPlaceholder')}
                            onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                            value={locationState.searchQuery}
                        />
                    </Box>

                    {role.isAdmin() ? (
                        <Box minWidth="250px">
                            <CustomerGroupSelect
                                onChange={handleGroupSelection}
                                value={locationState.customerGroupIds}
                                isMulti
                                placeholder={translate('customerGroupSelect.filterByCustomerGroups')}
                                dropdownWidth="520px"
                            />
                        </Box>
                    ) : null}
                </Box>

                <KeyPortOverviewTable />
            </PageGrid>
        </Page>
    );
};
