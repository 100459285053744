import { useLocation, useNavigate } from '@tanstack/react-router';
import PropTypes from 'prop-types';

import { IconButton } from '../../../cdl/IconButton/IconButton';
import { translate } from '../../../common/helpers/translate.helper';
import { IconCopy } from '../../../common/icons/cdl/Copy';

const getDuplicateTooltipForOrderState = (order) => {
    if (order.isDraft()) {
        return translate('actionListLabel.copyDraftTo');
    }

    if (order.isClosed()) {
        return translate('actionListLabel.copyTo');
    }

    return translate('actionListLabel.copyEnquiryTo');
};

export const DuplicateOrderButton = ({ order }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const onDuplicateOrderClick = (e) => {
        e.stopPropagation();

        if (order.type === 'FUEL') {
            navigate({
                to: '/fuel/create',
                search: {
                    order,
                },
                state: {
                    originLocation: location,
                },
            });
        } else {
            navigate({ to: '/orders/new', state: { order } });
        }
    };

    return (
        <IconButton icon={IconCopy} tooltip={getDuplicateTooltipForOrderState(order)} onClick={onDuplicateOrderClick} />
    );
};

DuplicateOrderButton.propTypes = {
    order: PropTypes.object.isRequired,
};
