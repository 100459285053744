import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Box } from '../../../common/ui/Box';

export const AssignmentsFilterSelects = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Box display="flex" gap={4}>
            <OverviewCustomerCompanySelect
                onChange={(customerIds) => {
                    setLocationFieldValue('customerIds', customerIds);
                    setLocationFieldValue('page', 0);
                }}
                value={locationState.customerIds}
            />

            <OverviewSupplierCompanySelect
                value={locationState.supplierIds}
                onChange={(supplierIds) => {
                    setLocationFieldValue('supplierIds', supplierIds);
                    setLocationFieldValue('page', 0);
                }}
            />
        </Box>
    );
};
