import { useState } from 'react';

import { Modal } from '../../cdl/Modal/Modal';
import { useAngularDependencies } from '../../common/hooks/useAngularDependencies';
import { useRole } from '../../common/hooks/useRole';

import { GroupAdminModalContent } from './GroupAdminModalContent';
import { useAcceptGtc } from './useAcceptGtc';
import { UserModalContent } from './UserModalContent';

export const AcceptTerms = () => {
    const role = useRole();
    const { SessionService } = useAngularDependencies();
    const acceptGtc = useAcceptGtc();
    const [showAcceptTerms, setShowAcceptTerms] = useState(!role.group?.gtcAccepted);

    const logOut = () => {
        SessionService.logout();
    };

    const onAccept = async (newsletter: boolean) => {
        acceptGtc(newsletter)
            .then(() => setShowAcceptTerms(false))
            .catch(() => logOut());
    };

    if (role.isAdmin()) {
        return null;
    }

    return (
        <Modal isOpen={showAcceptTerms} dismissible={false}>
            {role.hasAdminRights() ? (
                <GroupAdminModalContent onLogOut={logOut} onAccept={onAccept} />
            ) : (
                <UserModalContent onLogOut={logOut} />
            )}
        </Modal>
    );
};
