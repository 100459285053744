import invariant from 'tiny-invariant';

import { AngularDependencies } from '../../../types/AngularDependencies';

const ngDeps: {
    current: AngularDependencies | null;
} = { current: null };

export const setNgDeps = (deps: AngularDependencies) =>
    (ngDeps.current = {
        ...ngDeps.current,
        ...deps,
    });

export const getAngularDependencies = (): AngularDependencies => {
    invariant(
        ngDeps.current,
        "getAngularDependencies called, but ngDeps is empty. The application likely hasn't been initialised properly. Check run.ts or closelink.js"
    );
    return ngDeps.current;
};

/** @deprecated don't add new calls that depend on angular */
export const useAngularDependencies = getAngularDependencies;
