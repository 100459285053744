import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';

import { LoginPage } from '../login/components/LoginPage';

const LoginSearchSchema = z.object({
    email: z.string().email().optional().catch(undefined),
    redirect: z.string().url().optional().catch(undefined),
});

export const Route = createFileRoute('/login')({
    validateSearch: (search: Record<string, unknown>) => LoginSearchSchema.parse(search),
    component: LoginPage,
});
