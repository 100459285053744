import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from '@tanstack/react-router';
import FileDownload from 'js-file-download';
import PropTypes from 'prop-types';
import { useState } from 'react';
import styled from 'styled-components';

import { Button } from '../../cdl/Button/Button';
import { useToasts } from '../../cdl/Toast/useToasts';
import { updateVessel } from '../../common/api/clients/vessel.api';
import {
    downloadVesselRequisition,
    sendVesselRequisition as apiSendVesselRequisition,
} from '../../common/api/clients/vesselRequisition.api';
import { CL_DARK_BLUE_40 } from '../../common/colors';
import { extractFileName } from '../../common/helpers/extractFileName.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useRole } from '../../common/hooks/useRole';
import { IconDownload } from '../../common/icons/cdl/Download';
import { IconPlus } from '../../common/icons/cdl/Plus';
import { IconSend } from '../../common/icons/cdl/Send';
import { Box } from '../../common/ui/Box';

import { SendToVesselPopup } from './SendToVesselPopup';

const StyledLabel = styled.span`
    font-size: 14px;
    font-weight: 600;
    margin-right: 16px;
    margin-bottom: 0;
    color: ${CL_DARK_BLUE_40};
    text-wrap: nowrap;
`;

export const VesselRequisitionToolbar = (props) => {
    const queryClient = useQueryClient();
    const role = useRole();
    const navigate = useNavigate();
    const { addToast } = useToasts();

    const [showPopup, setShowPopup] = useState(false);

    const { mutateAsync: executeVesselRequisitionDownload, isPending: downloadVesselRequisitionPending } = useMutation({
        mutationFn: downloadVesselRequisition,
    });
    const { mutateAsync: executeSendVesselRequisition, isPending: sendVesselRequisitionPending } = useMutation({
        mutationFn: apiSendVesselRequisition,
    });
    const { mutateAsync: executeUpdateVessel } = useMutation({
        mutationFn: updateVessel,
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: [`vessel`, props.vessel.id],
            });
        },
    });

    const onPopupDismiss = () => {
        setShowPopup(false);
    };

    const onPopupSubmit = async ({ emailAddress }) => {
        setShowPopup(false);

        await executeUpdateVessel({
            vesselId: props.vessel.id,
            vessel: {
                ...props.vessel,
                email: emailAddress,
            },
        });

        await sendVesselRequisition();
    };

    const sendVesselRequisition = async () => {
        await executeSendVesselRequisition(props.vessel.id);

        addToast(translate('vessel.vesselRequisitionSentToast'));
    };

    const onClickSendButton = async () => {
        if (sendVesselRequisitionPending) {
            return;
        }

        if (!props.vessel.email) {
            setShowPopup(true);
        } else {
            await sendVesselRequisition();
        }
    };

    const onClickDownloadButton = async () => {
        const response = await executeVesselRequisitionDownload(props.vessel.id);
        FileDownload(
            response.data,
            extractFileName(response.headers['content-disposition'] || '', 'VesselRequisition.xlsx')
        );
    };

    const onClickNewEnquiryButton = () => {
        navigate({
            to: '/orders/new',
            state: {
                vesselId: props.vessel,
                customerId: props.vessel.customerId,
            },
        });
    };

    return (
        <div>
            <Box display="flex" alignItems="center" justifyContent="end" columnGap={4} rowGap={6} flexWrap="wrap">
                {!role.usesCloselinkLite() ? (
                    <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="end">
                        <StyledLabel>{translate('vessel.requisitionForm.label')}</StyledLabel>
                        <Box display="flex" alignItems="center" gap={4}>
                            <Button
                                onClick={onClickDownloadButton}
                                leadingVisual={<IconDownload height={16} width={16} />}
                                isLoading={downloadVesselRequisitionPending}
                            >
                                {translate('vessel.requisitionForm.downloadButton')}
                            </Button>
                            <Button
                                onClick={onClickSendButton}
                                isLoading={sendVesselRequisitionPending}
                                leadingVisual={<IconSend height={16} width={16} />}
                            >
                                {translate('vessel.requisitionForm.sendToVesselButton')}
                            </Button>
                        </Box>
                    </Box>
                ) : null}

                {role.isCustomer() ? (
                    <Box paddingLeft="40px">
                        <Button
                            onClick={onClickNewEnquiryButton}
                            emphasis="high"
                            disabled={!props.vessel.active}
                            leadingVisual={<IconPlus height={16} width={16} />}
                            tooltip={!props.vessel.active ? translate('vessel.newEnquiryDisabledHint') : null}
                        >
                            {translate('vessel.newEnquiry')}
                        </Button>
                    </Box>
                ) : null}

                {showPopup && (
                    <SendToVesselPopup open={showPopup} onDismiss={onPopupDismiss} onSubmit={onPopupSubmit} />
                )}
            </Box>
        </div>
    );
};

VesselRequisitionToolbar.propTypes = {
    vessel: PropTypes.object,
};
