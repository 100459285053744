import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';

import { CreateProductButton } from './components/CreateProductButton';
import { ProductsSearch } from './components/ProductsSearch';
import { ProductsTable } from './components/ProductsTable';

export interface ProductsOverviewStateConfig {
    page: number;
    searchQuery: string;
}

export const ProductsOverviewPage = () => {
    useDocumentTitle(translate('page.productsOverview'));

    const stateConfig: ProductsOverviewStateConfig = {
        page: 0,
        searchQuery: '',
    };

    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <Page>
                <PageGrid>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
                        <Text variant="headline" as="h1">
                            {translate('productsOverview.headline')}
                        </Text>

                        <CreateProductButton />
                    </Box>

                    <ProductsSearch />

                    <ProductsTable />
                </PageGrid>
            </Page>
        </LocationStateProvider>
    );
};
