import { Button } from '../../cdl/Button/Button';
import { useDrawer } from '../../cdl/Drawer/useDrawer.hook';
import { Text } from '../../cdl/Text/Text';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { Page } from '../../common/Page/Page';
import { PageGrid } from '../../common/PageGrid';
import { Box } from '../../common/ui/Box';

import { CreateSupplierGroup } from './components/CreateSupplierGroup';
import { SupplierGroupSearch } from './components/SupplierGroupSearch';
import { SupplierGroupsTable } from './components/SupplierGroupsTable';

export interface SupplierGroupsOverviewStateConfig {
    page: number;
    searchQuery: string;
}

export const SupplierGroupsOverviewPage = () => {
    useDocumentTitle(translate('page.suppliergroupoverview'));

    const { push, pop } = useDrawer();

    const stateConfig: SupplierGroupsOverviewStateConfig = {
        page: 0,
        searchQuery: '',
    };

    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <Page>
                <PageGrid>
                    <Box display="flex" justifyContent="space-between" alignItems="center" gap={5}>
                        <Text variant="headline" as="h1">
                            {translate('suppliergroups.headline')}
                        </Text>

                        <Button
                            emphasis="high"
                            onClick={() => push({ content: <CreateSupplierGroup onSuccess={pop} /> })}
                        >
                            {translate('suppliergroups.create')}
                        </Button>
                    </Box>

                    <SupplierGroupSearch />

                    <SupplierGroupsTable />
                </PageGrid>
            </Page>
        </LocationStateProvider>
    );
};
