import { useNavigate } from '@tanstack/react-router';

import { CenteredPagination } from '../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../cdl/TableBuilder/TableBuilderColumn';
import { Error } from '../../common/Error/Error';
import { formatAddress } from '../../common/helpers/formatAddress.helper';
import { formatCompanyName } from '../../common/helpers/formatCompanyName.helper';
import { translate } from '../../common/helpers/translate.helper';
import { useProductContext } from '../../common/hooks/useProductContext';
import { useRole } from '../../common/hooks/useRole';
import { useUpdateEffect } from '../../common/hooks/useUpdateEffect';
import { IconAnchor } from '../../common/icons/cdl/Anchor';
import { IconCheck } from '../../common/icons/cdl/Check';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { TableEmptyState } from '../../common/TableEmptyState/TableEmptyState';
import { TabSwitch } from '../../common/TabSwitch/TabSwitch';
import { useVesselPagination } from '../useVesselPagination';

import { useVesselPaginationTotals } from './useVesselPaginationTotals';

export const VesselOverviewTable = () => {
    const [locationState, { setLocationFieldValue }] = useLocationState();

    const navigate = useNavigate();
    const role = useRole();
    const { isFuel, isLubes, context } = useProductContext();
    const active = locationState.tab === 'active';

    const handleCustomerIds = (customerIds) => {
        if (customerIds.length) {
            return customerIds;
        }

        if (role.isAdmin()) {
            return null;
        }

        return role.getCompaniesWithType(context).map((it) => it.id);
    };

    const { data: vesselTotals } = useVesselPaginationTotals({
        searchQuery: locationState.searchQuery,
        customerIds: handleCustomerIds(locationState.customerIds),
    });

    const { data, isPending, isError, pageTotal } = useVesselPagination({
        page: locationState.page,
        searchQuery: locationState.searchQuery,
        customerIds: handleCustomerIds(locationState.customerIds),
        active,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [locationState.searchQuery, locationState.customerIds, locationState.tab]);

    const totals = {
        active: vesselTotals?.active,
        inactive: vesselTotals?.inactive,
    };

    if (isPending) {
        return (
            <div>
                <TabSwitch
                    selectedContext={locationState.tab}
                    onSelect={(tab) => setLocationFieldValue('tab', tab)}
                    totals={totals}
                />
                <LoadingIndicator />
            </div>
        );
    }

    if (isError) {
        return (
            <div>
                <TabSwitch
                    selectedContext={locationState.tab}
                    onSelect={(tab) => setLocationFieldValue('tab', tab)}
                    totals={totals}
                />

                <Error />
            </div>
        );
    }

    if (!data.content.length) {
        return (
            <div>
                <TabSwitch
                    selectedContext={locationState.tab}
                    onSelect={(tab) => setLocationFieldValue('tab', tab)}
                    totals={totals}
                />
                <TableEmptyState Icon={IconAnchor} text={translate('vessel.emptylist')} />
            </div>
        );
    }

    const handleRowClick = (vessel) => {
        if (!role.isAdmin() && isFuel) {
            navigate({
                to: '/fuel/vessel/$id',
                params: {
                    id: vessel.id,
                },
            });
            return;
        }
        navigate({
            to: '/vessel/$id',
            params: {
                id: vessel.id,
            },
        });
    };

    return (
        <div>
            <TabSwitch
                selectedContext={locationState.tab}
                onSelect={(tab) => setLocationFieldValue('tab', tab)}
                totals={totals}
            />

            <TableBuilder data={data.content} onRowSelect={handleRowClick}>
                <TableBuilderColumn header={translate('vessel.name')} width="25%">
                    {(vessel) => vessel.name}
                </TableBuilderColumn>
                <TableBuilderColumn header={translate('vessel.imo')} width="15%">
                    {(vessel) => vessel.imo}
                </TableBuilderColumn>
                {role.isAdmin() || !role.isOneCompanyUser(context) ? (
                    <TableBuilderColumn header={translate('vessel.company')} width="25%">
                        {(vessel) =>
                            vessel.customer
                                ? formatCompanyName({
                                      company: vessel.customer,
                                      includeType: role.isAdmin(),
                                  })
                                : ''
                        }
                    </TableBuilderColumn>
                ) : null}
                {isLubes ? (
                    <TableBuilderColumn header={translate('vessel.defaultsupplier')} width="20%">
                        {(vessel) => vessel.defaultSupplier?.name || ''}
                    </TableBuilderColumn>
                ) : null}
                {role.isAdmin() ? (
                    <TableBuilderColumn header={translate('vessel.paying')}>
                        {(vessel) => (vessel.paying ? <IconCheck width={16} height={16} /> : null)}
                    </TableBuilderColumn>
                ) : null}
                {role.isCustomer() ? (
                    <TableBuilderColumn header={translate('vessel.invoiceaddress')}>
                        {(vessel) => (vessel.invoiceAddress ? formatAddress({ address: vessel.invoiceAddress }) : '')}
                    </TableBuilderColumn>
                ) : null}
            </TableBuilder>
            <CenteredPagination
                pageTotal={pageTotal}
                currentPage={locationState.page}
                onPageChange={(page) => setLocationFieldValue('page', page)}
            />
        </div>
    );
};
