import { createFileRoute, redirect } from '@tanstack/react-router';

import { getAngularDependencies } from '../common/hooks/useAngularDependencies';
import { AblyService } from '../common/services/AblyService';
import { queryClient } from '../lib/queryClient';

export const Route = createFileRoute('/logout')({
    loader() {
        const { RoleService, SessionStorageService } = getAngularDependencies();

        SessionStorageService.resetToken();
        RoleService.reset();
        AblyService.disconnect();
        queryClient.clear();

        throw redirect({
            to: '/login',
            // replacing history in order to prevent back-button to contain
            // potentially confidential data
            replace: true,
        });
    },
});
