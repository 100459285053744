import { ProductMessage } from '../../../../types/ProductMessage';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { InfoPopover } from '../../../common/InfoPopover/InfoPopover';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { Flex } from '../../../common/ui/Flex';
import { useProductsPagination } from '../hooks/useProductsPagination';
import { ProductsOverviewStateConfig } from '../ProductsOverviewPage';

import { UpdateProductButton } from './UpdateProductButton';

export const ProductsTable = () => {
    const { getHumanReadableValue } = useEnums();
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as ProductsOverviewStateConfig;
    const productsPaginationQuery = useProductsPagination(locationState.searchQuery, locationState.page);

    if (productsPaginationQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (productsPaginationQuery.isError) {
        return <CenteredPageError />;
    }

    if (productsPaginationQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder data={productsPaginationQuery.data.content}>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.name')}>
                    {(row) => row.name}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.supplier')}>
                    {(row) => getHumanReadableValue('supplierProductGroup', row.supplierProductGroup)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.group')}>
                    {(row) => getHumanReadableValue('productGroup', row.group)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.packType')}>
                    {(row) => getHumanReadableValue('packType', row.packTypeDefault)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.unitDefault')}>
                    {(row) => getHumanReadableValue('productUnit', row.unitDefault)}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.publicId')}>
                    {(row) => (
                        <Flex alignItems="center" gap={3}>
                            {row.publicId}
                            <InfoPopover>
                                {translate('productsOverview.labels.internalId', {
                                    internalId: row.id,
                                })}
                            </InfoPopover>
                        </Flex>
                    )}
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header={translate('productsOverview.labels.state')}>
                    {(row) =>
                        row.archived
                            ? translate('productsOverview.labels.archived')
                            : translate('productsOverview.labels.active')
                    }
                </TableBuilderColumn>
                <TableBuilderColumn<ProductMessage> header="">
                    {(row) => <UpdateProductButton product={row} />}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={locationState.page}
                pageTotal={Math.ceil(productsPaginationQuery.data.total / productsPaginationQuery.data.pageable.size)}
                onPageChange={(page) => {
                    setLocationFieldValue('page', page);
                }}
            />
        </div>
    );
};
