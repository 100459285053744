import { Text } from '../../../cdl/Text/Text';
import { translate } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

import { CreatePortButton } from './components/CreatePortButton';
import { PortsOverviewState } from './components/PortsOverviewState';
import { PortsOverviewTable } from './components/PortsOverviewTable';

export const PortsOverviewPage = () => {
    return (
        <PortsOverviewState>
            <PortsOverviewPageContent />
        </PortsOverviewState>
    );
};

const PortsOverviewPageContent = () => {
    const title = translate('ports.headline');
    useDocumentTitle(title);

    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Page>
            <PageGrid>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Text variant="headline" as="h1">
                        {translate('ports.headline')}
                    </Text>

                    <CreatePortButton />
                </Box>

                <Box width="250px">
                    <DebouncedSearchTextInput
                        onChange={(searchQuery) => {
                            setLocationFieldValue('searchQuery', searchQuery);
                            setLocationFieldValue('page', 0);
                        }}
                        value={locationState.searchQuery}
                        placeholder={translate('placeholder.port')}
                    />
                </Box>

                <PortsOverviewTable />
            </PageGrid>
        </Page>
    );
};
