import { createFileRoute } from '@tanstack/react-router';

import { queries } from '../common/api/queryKeys/queries';
import { getRole } from '../common/hooks/useRole';
import { queryClient } from '../lib/queryClient';
import { VesselDetailPage } from '../vessel/VesselDetailPage/VesselDetailPage';

export const Route = createFileRoute('/_app/vessel/$id')({
    async loader({ params: { id } }) {
        queryClient.fetchQuery({ ...queries.vessels.detail(id) }).then((vessel) => {
            getRole().updateContext(vessel?.customer.type);
        });
    },
    component: VesselDetailPage,
});
