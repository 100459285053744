import translationsEn from '../../../translations/en/translation.json';
import { getAngularDependencies } from '../hooks/useAngularDependencies';

// Copied from https://stackoverflow.com/a/8817461/3276759
function deepFind(obj: any, path: string): string | undefined {
    const paths = path.split('.');
    let current = obj;
    let i;

    for (i = 0; i < paths.length; ++i) {
        if (current[paths[i]] === undefined) {
            return undefined;
        } else {
            current = current[paths[i]];
        }
    }
    return current;
}

interface KeyReplacement {
    key: string;
    value: string;
}

interface Replacements {
    [key: string]: string;
}

type DotPrefix<T extends string> = T extends '' ? '' : `.${T}`;

type DotNestedKeys<T> = (
    T extends object
        ? { [K in Exclude<keyof T, symbol>]: `${K}${DotPrefix<DotNestedKeys<T[K]>>}` }[Exclude<keyof T, symbol>]
        : ''
) extends infer D
    ? Extract<D, string>
    : never;

export type TranslationKeyEn = DotNestedKeys<typeof translationsEn>;

const getTranslationByKey = (key: TranslationKeyEn, replacements?: Replacements) => {
    const translation = deepFind(translationsEn, key);

    if (!translation) {
        return '';
    }

    if (!replacements) {
        return translation;
    }

    const keyReplacements: KeyReplacement[] = [];

    for (const [key, value] of Object.entries(replacements)) {
        const replaceableKey = `{{${key}}}`;
        keyReplacements.push({ key: replaceableKey, value });
    }

    let translationWithReplacements = translation;

    keyReplacements.forEach((entry) => {
        translationWithReplacements = translationWithReplacements.replace(entry.key, entry.value);
    });

    return translationWithReplacements;
};

export const translate = (key: TranslationKeyEn, replacements?: Replacements): string => {
    if (import.meta.env.MODE === 'test' || import.meta.env.STORYBOOK) {
        return getTranslationByKey(key, replacements);
    }

    const { $translate } = getAngularDependencies();

    return $translate.instant(key, replacements);
};

export const conditionalTranslate = (
    bool: boolean,
    trueKey: TranslationKeyEn,
    falseKey: TranslationKeyEn,
    replacements?: Replacements
): string => {
    return translate(bool ? trueKey : falseKey, replacements);
};

export const translateOrNull = (key: TranslationKeyEn, replacement?: Replacements): null | string => {
    const translation = translate(key, replacement);

    if (!translation || translation === key) {
        return null;
    }

    return translation;
};
