import { ProductContext } from '../../../../types/ProductContext';
import { Text } from '../../../cdl/Text/Text';
import { OverviewCustomerCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewCustomerCompanySelect';
import { OverviewSupplierCompanySelect } from '../../../common/form-elements/CompanySelect/OverviewSupplierCompanySelect';
import { translate as t } from '../../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../../common/hooks/useDocumentTitle';
import { useProductContext } from '../../../common/hooks/useProductContext';
import { useRole } from '../../../common/hooks/useRole';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { Page } from '../../../common/Page/Page';
import { PageGrid } from '../../../common/PageGrid';
import { DebouncedSearchTextInput } from '../../../common/Search/DebouncedSearchTextInput/DebouncedSearchTextInput';
import { Box } from '../../../common/ui/Box';

import { LubesOfferOverviewSortSelect } from './LubesOfferOverviewSortSelect';
import { LubesOfferOverviewState } from './LubesOfferOverviewState';
import { LubesOfferOverviewTable } from './LubesOfferOverviewTable';

export const LubesOfferOverviewPage = () => {
    return (
        <LubesOfferOverviewState>
            <LubesOfferOverviewPageContent />
        </LubesOfferOverviewState>
    );
};

const LubesOfferOverviewPageContent = () => {
    const role = useRole();
    const { context } = useProductContext();

    const title = t(role.isAdmin() ? 'offer.overviewPageTitle' : 'order.overviewPageTitle');
    useDocumentTitle(title);

    const [locationState, { setLocationFieldValue }] = useLocationState();

    return (
        <Page>
            <PageGrid>
                <Text variant="headline" as="h1">
                    {title}
                </Text>

                <Box display="flex" justifyContent="space-between" columnGap={4}>
                    <Box display="flex" columnGap={4}>
                        <Box minWidth="250px">
                            <DebouncedSearchTextInput
                                placeholder={
                                    role.isAdmin()
                                        ? t('offer.searchInputPlaceholder')
                                        : t('order.searchInputPlaceholder')
                                }
                                value={locationState.searchQuery}
                                onChange={(searchQuery) => setLocationFieldValue('searchQuery', searchQuery)}
                            />
                        </Box>

                        {role.isAdmin() ? (
                            <OverviewCustomerCompanySelect
                                context={ProductContext.LUBES}
                                value={locationState.customerIds}
                                onChange={(customerIds) => {
                                    setLocationFieldValue('customerIds', customerIds);
                                }}
                            />
                        ) : null}

                        {!role.isOneCompanyUser(context) || role.isAdmin() ? (
                            <OverviewSupplierCompanySelect
                                context={ProductContext.LUBES}
                                value={locationState.supplierIds}
                                onChange={(supplierIds) => {
                                    setLocationFieldValue('supplierIds', supplierIds);
                                }}
                            />
                        ) : null}
                    </Box>

                    <Box minWidth="250px">
                        <LubesOfferOverviewSortSelect
                            onChange={(sortOption) => {
                                setLocationFieldValue('sortValue', sortOption);
                            }}
                            value={locationState.sortValue}
                        />
                    </Box>
                </Box>

                <LubesOfferOverviewTable />
            </PageGrid>
        </Page>
    );
};
