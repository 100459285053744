import { useState } from 'react';
import { OnChangeValue } from 'react-select';

import { NoFilterResults } from '../../cdl/Filter/NoFilterResults/NoFilterResults';
import { Text } from '../../cdl/Text/Text';
import { EmptyPagePlaceholder } from '../../common/EmptyPagePlaceholder/EmptyPagePlaceholder';
import { CenteredPageError } from '../../common/Error/CenteredPageError';
import { CustomerGroupSelect } from '../../common/form-elements/CustomerGroupSelect/CustomerGroupSelect';
import { translate } from '../../common/helpers/translate.helper';
import { useDocumentTitle } from '../../common/hooks/useDocumentTitle';
import { useRole } from '../../common/hooks/useRole';
import { IconGameplan } from '../../common/icons/cdl/Gameplan';
import { LoadingIndicator } from '../../common/LoadingIndicator/LoadingIndicator';
import { LocationStateProvider } from '../../common/LocationState/LocationStateProvider';
import { useLocationState } from '../../common/LocationState/useLocationState';
import { Page } from '../../common/Page/Page';
import { Box } from '../../common/ui/Box';
import { Flex } from '../../common/ui/Flex';
import { theme } from '../../common/ui/theme';
import { useBunkerMetricCredentials } from '../../globalPrices/hooks/useBunkerMetricCredentials';
import { DemandAccordion } from '../components/DemandAccordion';
import { DemandSortingSelect } from '../components/DemandSortingSelect';
import { DemandTab } from '../components/DemandTab';
import { PrePlanningTabSwitch } from '../components/PrePlanningTabSwitch';
import { SortingKey } from '../components/SortingKey';
import { VesselDemandFilter } from '../components/VesselDemandFilter';
import { useFuelDemandInformation } from '../hooks/useFuelDemandInformation';

interface PlanningContentProps {
    prePlanningEnabled: boolean;
}

const DemandOverviewLoading = ({ showEmptyPagePlaceholder }: { showEmptyPagePlaceholder: boolean }) => {
    if (showEmptyPagePlaceholder) {
        return (
            <Flex justifyContent="center" paddingTop="88px">
                <EmptyPagePlaceholder
                    title={translate('demand.emptyPageAdmin.title')}
                    description={translate('demand.emptyPageAdmin.subtitle')}
                    icon={<IconGameplan height={24} width={24} color={theme.colors.foreground.subtle} />}
                />
            </Flex>
        );
    }

    return <LoadingIndicator variant="page" />;
};

const PlanningContent = ({ prePlanningEnabled }: PlanningContentProps) => {
    const role = useRole();

    const [sortingKey, setSortingKey] = useState(SortingKey.DAYS_LEFT_SAILING);
    const [locationState, { setLocationFieldValue }] = useLocationState();
    const demandInformationQuery = useFuelDemandInformation(sortingKey, prePlanningEnabled, locationState.groupId);

    const onGroupChange = (option: OnChangeValue<string, false>) => {
        if (option !== null) {
            setLocationFieldValue('groupId', option);
        }
    };

    return (
        <>
            <Box display="flex" justifyContent="space-between" columnGap={6} marginBottom={5}>
                <Text
                    as="h1"
                    variant="headline"
                    style={{
                        whiteSpace: 'nowrap',
                    }}
                >
                    {translate('demand.planning')}
                </Text>

                <Box display="flex" columnGap={4}>
                    {role.isAdmin() ? (
                        <Box width="300px">
                            <CustomerGroupSelect
                                isMulti={false}
                                dropdownWidth="520px"
                                onChange={onGroupChange}
                                value={locationState.groupId}
                                placeholder={translate('demand.groupSelectPlaceholder')}
                            />
                        </Box>
                    ) : null}
                    <Box width="300px">
                        <DemandSortingSelect
                            value={sortingKey}
                            onChange={(option) => {
                                if (option) {
                                    setSortingKey(option.value);
                                }
                            }}
                        />
                    </Box>
                </Box>
            </Box>

            {prePlanningEnabled ? <PrePlanningTabSwitch currentTab={DemandTab.VESSELS} /> : null}

            {demandInformationQuery.isPending ? (
                <DemandOverviewLoading showEmptyPagePlaceholder={role.isAdmin() && !locationState.groupId} />
            ) : null}
            {demandInformationQuery.isError ? <CenteredPageError /> : null}

            {demandInformationQuery.isSuccess ? (
                <Box marginTop={prePlanningEnabled ? 5 : 10}>
                    <VesselDemandFilter
                        allEntries={demandInformationQuery.data.vessels}
                        groupId={locationState.groupId}
                    >
                        {(filteredItems) => {
                            if (filteredItems.length === 0) {
                                return <NoFilterResults />;
                            }

                            return (
                                <Box display="grid" rowGap={5}>
                                    {filteredItems.map((vessel) => {
                                        if (!vessel.categories.length) {
                                            return <DemandAccordion key={vessel.id} vessel={vessel} disabled />;
                                        }

                                        return <DemandAccordion key={vessel.id} vessel={vessel} />;
                                    })}
                                </Box>
                            );
                        }}
                    </VesselDemandFilter>
                </Box>
            ) : null}
        </>
    );
};

export const PlanningPage = () => {
    useDocumentTitle(translate('demand.planning'));
    const role = useRole();

    const bunkerMetricCredentialsQuery = useBunkerMetricCredentials();

    const stateConfig: { groupId?: string } = {
        groupId: undefined,
    };

    if (bunkerMetricCredentialsQuery.isPending) {
        return (
            <Page>
                <LoadingIndicator variant="page" />
            </Page>
        );
    }

    if (bunkerMetricCredentialsQuery.isError) {
        return (
            <Page>
                <CenteredPageError />
            </Page>
        );
    }

    return (
        <LocationStateProvider stateConfig={stateConfig}>
            <Page>
                <PlanningContent
                    prePlanningEnabled={bunkerMetricCredentialsQuery.data.credentials.length > 0 && !role.isAdmin()}
                />
            </Page>
        </LocationStateProvider>
    );
};
