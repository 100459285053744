import { useNavigate } from '@tanstack/react-router';
import React, { SyntheticEvent } from 'react';

import { UserGatewayMessage } from '../../../../types/UserGatewayMessage';
import { UserStatus } from '../../../../types/UserStatus';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { Switch } from '../../../cdl/Switch/Switch';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { Text } from '../../../cdl/Text/Text';
import { CompanyListing } from '../../../common/CompanyListing/CompanyListing';
import { Error } from '../../../common/Error/Error';
import { formatDate } from '../../../common/helpers/formatDate.helper';
import { identifyUserRole } from '../../../common/helpers/identifyUserRole.helper';
import { translate } from '../../../common/helpers/translate.helper';
import { useEnums } from '../../../common/hooks/useEnums';
import { useRole } from '../../../common/hooks/useRole';
import { useUpdateEffect } from '../../../common/hooks/useUpdateEffect';
import { IconUsers } from '../../../common/icons/cdl/Users';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { TableEmptyState } from '../../../common/TableEmptyState/TableEmptyState';
import { Box } from '../../../common/ui/Box';
import { useManagementUsersPagination } from '../hooks/useManagementUsers';
import { useOptimisticUserMutation } from '../hooks/useOptimisticUserMutation';

import { EditUserTableActionButton } from './EditUserTableActionButton';

export const UserOverviewTable = () => {
    const role = useRole();
    const navigate = useNavigate();
    const [locationState, { setLocationFieldValue }] = useLocationState();
    const { getHumanReadableValue } = useEnums();
    const userMutation = useOptimisticUserMutation();

    const usersPaginationQuery = useManagementUsersPagination({
        companyIds: [...locationState.supplierIds, ...locationState.customerIds],
        groupIds: [...locationState.supplierGroupIds, ...locationState.customerGroupIds],
        page: locationState.page,
        searchQuery: locationState.searchQuery,
    });

    useUpdateEffect(() => {
        setLocationFieldValue('page', 0);
    }, [
        locationState.searchQuery,
        locationState.supplierIds,
        locationState.customerIds,
        locationState.supplierGroupIds,
        locationState.customerGroupIds,
    ]);

    if (usersPaginationQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (usersPaginationQuery.isError) {
        return <Error />;
    }

    if (!usersPaginationQuery.data.content.length) {
        return <TableEmptyState Icon={IconUsers} text={translate('user.emptyList')} />;
    }

    const onRowClick = (user: UserGatewayMessage) => {
        navigate({
            to: '/user/detail/$id',
            params: {
                id: user.id,
            },
        });
    };

    return (
        <div>
            <TableBuilder data={usersPaginationQuery.data.content} onRowSelect={onRowClick}>
                <TableBuilderColumn<UserGatewayMessage>
                    header={translate('user.name')}
                    width={role.isAdmin() ? '15%' : '20%'}
                >
                    {(user) => {
                        if (user.firstname && user.lastname) {
                            return `${user.firstname} ${user.lastname}`;
                        }

                        if (user.firstname) {
                            return user.firstname;
                        }

                        if (user.lastname) {
                            return user.lastname;
                        }

                        return null;
                    }}
                </TableBuilderColumn>
                <TableBuilderColumn<UserGatewayMessage>
                    header={translate('user.mail')}
                    width={role.isAdmin() ? '15%' : '20%'}
                >
                    {(user) => user.email}
                </TableBuilderColumn>

                {role.isAdmin() ? (
                    <TableBuilderColumn<UserGatewayMessage>
                        header={translate('user.group')}
                        width={role.isAdmin() ? '10%' : '15%'}
                    >
                        {(user) => user.group?.name}
                    </TableBuilderColumn>
                ) : null}

                {role.isAdmin() ? (
                    <TableBuilderColumn<UserGatewayMessage> header={translate('user.userType')} width="10%">
                        {(user) => getHumanReadableValue('userType', user.userType)}
                    </TableBuilderColumn>
                ) : null}

                <TableBuilderColumn<UserGatewayMessage>
                    header={translate('user.role')}
                    width={role.isAdmin() ? '15%' : '20%'}
                >
                    {(user) => getHumanReadableValue('role', identifyUserRole(user))}
                </TableBuilderColumn>
                <TableBuilderColumn<UserGatewayMessage>
                    header={translate('user.companies')}
                    width={role.isAdmin() ? '15%' : '20%'}
                >
                    {(user) => {
                        const companies =
                            user.companyAccesses
                                ?.filter((companyAccess) => companyAccess.company)
                                .map((companyAccess) => companyAccess.company)
                                .sort((c1, c2) => {
                                    if (!c1 || !c2) {
                                        return 0;
                                    }

                                    return c1.name.localeCompare(c2.name);
                                }) ?? null;

                        if (!companies) {
                            return null;
                        }

                        return <CompanyListing includeType={role.isAdmin()} companies={companies} />;
                    }}
                </TableBuilderColumn>
                <TableBuilderColumn<UserGatewayMessage> header={translate('user.datecreated')} width="10%">
                    {(user) => formatDate({ date: user.dateCreated })}
                </TableBuilderColumn>
                <TableBuilderColumn<UserGatewayMessage> header={translate('user.statusHeader')} width="10%" interactive>
                    {(user) => {
                        if (role.user.id === user.id) {
                            return (
                                <Text fontStyle="italic" color="foreground.subtle">
                                    {translate('user.you')}
                                </Text>
                            );
                        }

                        if (user.status === UserStatus.INVITED) {
                            return (
                                <Text fontStyle="italic" color="foreground.subtle">
                                    {getHumanReadableValue('status', UserStatus.INVITED)}
                                </Text>
                            );
                        }

                        const onUserChecked = (
                            _: boolean,
                            event: SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent | undefined
                        ) => {
                            event?.stopPropagation();

                            const newStatus =
                                user.status === UserStatus.ACTIVE ? UserStatus.INACTIVE : UserStatus.ACTIVE;

                            userMutation.mutate({
                                userId: user.id,
                                userMessage: {
                                    ...user,
                                    status: newStatus,
                                },
                            });
                        };

                        return (
                            <Box
                                display="flex"
                                alignItems="center"
                                style={{
                                    gap: '8px',
                                }}
                            >
                                <Switch checked={user.status === UserStatus.ACTIVE} onChange={onUserChecked} />
                            </Box>
                        );
                    }}
                </TableBuilderColumn>
                <TableBuilderColumn<UserGatewayMessage> header="" interactive>
                    {(user) => {
                        if (user.status === UserStatus.INVITED && !role.isAdmin()) {
                            return null;
                        }

                        return <EditUserTableActionButton user={user} />;
                    }}
                </TableBuilderColumn>
            </TableBuilder>

            {usersPaginationQuery.pageTotal > 1 ? (
                <CenteredPagination
                    currentPage={locationState.page}
                    pageTotal={usersPaginationQuery.pageTotal}
                    onPageChange={(page) => setLocationFieldValue('page', page)}
                />
            ) : null}
        </div>
    );
};
