import { AssignmentGatewayMessage } from '../../../../types/AssignmentGatewayMessage';
import { CompanyMessage } from '../../../../types/CompanyMessage';
import { ProductContext } from '../../../../types/ProductContext';
import { CenteredPagination } from '../../../cdl/Pagination/CenteredPagination';
import { TableBuilder } from '../../../cdl/TableBuilder/TableBuilder';
import { TableBuilderColumn } from '../../../cdl/TableBuilder/TableBuilderColumn';
import { CenteredPageError } from '../../../common/Error/CenteredPageError';
import { translate } from '../../../common/helpers/translate.helper';
import { useRole } from '../../../common/hooks/useRole';
import { LoadingIndicator } from '../../../common/LoadingIndicator/LoadingIndicator';
import { useLocationState } from '../../../common/LocationState/useLocationState';
import { NoSearchResults } from '../../../common/Search/NoSearchResults/NoSearchResults';
import { AssignmentStatusBadge } from '../../common/components/AssignmentStatusBadge';
import { useAssignmentsPagination } from '../../common/hooks/useAssignmentsPagination';
import { CustomersOverviewStateConfig } from '../CustomersOverviewPage';

export const CustomersOverviewTable = () => {
    const role = useRole();
    const [locationStateUntyped, { setLocationFieldValue }] = useLocationState();
    const locationState = locationStateUntyped as CustomersOverviewStateConfig;

    const supplierIds =
        locationState.supplierIds?.length > 0
            ? locationState.supplierIds
            : role.getCompaniesWithType(ProductContext.LUBES).map((company: CompanyMessage) => company.id);

    const customersPaginationQuery = useAssignmentsPagination({ supplierIds, page: locationState.page });

    if (customersPaginationQuery.isPending) {
        return <LoadingIndicator />;
    }

    if (customersPaginationQuery.isError) {
        return <CenteredPageError />;
    }

    if (customersPaginationQuery.data.content.length === 0) {
        return <NoSearchResults variant="page" />;
    }

    return (
        <div>
            <TableBuilder data={customersPaginationQuery.data.content}>
                <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.name')}>
                    {(row) => row.customer.name}
                </TableBuilderColumn>
                {!role.isOneCompanyUser(ProductContext.LUBES) ? (
                    <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.company')}>
                        {(row) => row.supplier.name}
                    </TableBuilderColumn>
                ) : null}
                <TableBuilderColumn<AssignmentGatewayMessage> header={translate('assignments.status')}>
                    {(row) => <AssignmentStatusBadge status={row.status} />}
                </TableBuilderColumn>
            </TableBuilder>

            <CenteredPagination
                currentPage={locationState.page}
                pageTotal={Math.ceil(customersPaginationQuery.data.total / customersPaginationQuery.data.pageable.size)}
                onPageChange={(page) => {
                    setLocationFieldValue('page', page);
                }}
            />
        </div>
    );
};
