import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';

import { getOfferById } from '../../../../common/api/clients/gateway.api';
import { markOfferRead } from '../../../../common/api/clients/order.api';
import { queryKeys } from '../../../../common/api/queryKeys';
import { useAbly } from '../../../../common/hooks/useAbly';
import { useRole } from '../../../../common/hooks/useRole';

export const useOfferDetails = (id) => {
    const queryClient = useQueryClient();
    const markOfferReadMutation = useMutation({ mutationFn: markOfferRead });
    const { subscribe, unsubscribe } = useAbly();
    const role = useRole();

    const query = useQuery({
        queryKey: queryKeys.offer(id),
        queryFn: () => getOfferById(id),
        enabled: Boolean(id),
    });

    const offer = query.data;

    useEffect(() => {
        const companyId = role.isCustomer() ? offer?.customerId : offer?.supplierId;

        // Only mark offer as read once after initial fetch
        if (!query.isSuccess && Boolean(id) && !role.isAdmin() && role.hasWriteRights(companyId)) {
            markOfferReadMutation.mutate({
                offerId: id,
                read: true,
            });
        }
    }, [query.isSuccess, id]); // eslint-disable-line react-hooks/exhaustive-deps

    const prefetch = (offerId) => {
        return queryClient.prefetchQuery({ queryKey: queryKeys.offer(offerId), queryFn: () => getOfferById(offerId) });
    };

    useEffect(() => {
        if (!offer) {
            return;
        }

        const topic = role.isSupplier()
            ? `suppliers:${offer.supplierId}:offers:${offer.id}`
            : `customers:${offer.customerId}:orders:${offer.orderId}:offers:${offer.id}`;

        subscribe(topic, () => {
            query.refetch();
        });
        return () => {
            unsubscribe(topic);
        };
    }, [offer, role, query, subscribe, unsubscribe]);

    useEffect(() => {
        if (!offer) {
            return;
        }

        const counterOfferTopic = role.isSupplier()
            ? `suppliers:${offer.supplierId}:offers:${offer.id}:counter-offers`
            : `customers:${offer.customerId}:offers:${offer.id}:counter-offers`;

        subscribe(counterOfferTopic, () => {
            query.refetch();
        });
        return () => {
            unsubscribe(counterOfferTopic);
        };
    }, [offer, role, query, subscribe, unsubscribe]);

    return {
        ...query,
        prefetch,
    };
};
